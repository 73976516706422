/* GLOBAL
-------------------------------------------------*/
html {
    box-sizing: border-box;
    font-size: 85%;
    @include breakpoint(small) {
        font-size: 90%;
    }
    @include breakpoint(large) {
        font-size: 100%;
    }
}

*,
*:after,
*:before {
    box-sizing: inherit;
}

body {
    margin: 0;
    color: $colorBase;
    font-family: $fontFamilyPrimary;
    font-size: 0.875rem;
    line-height: 1.6;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

.map {
    height: 450px;
}