/* ABOUT
-------------------------------------------------*/
.about {
    @extend %space;
background: $colorBg;

    .media {
        margin-bottom: $space;
    }
}
