/* FOOTER
-------------------------------------------------*/
.main-footer {

    background: lighten($colorPrimary, 2%);
    text-align: center;
    small {
        display: block;
    }
}
