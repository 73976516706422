/* HERO
-------------------------------------------------*/
.hero {
    position: relative;
    margin-top: 70px;
    .container-fluid {
        position: relative;
    }
    .icon {
        margin-right: 10px;
        color: $colorPrimary;
        vertical-align: top;
    }
}

.hero-content {
    padding: $space 0;
    background: rgba($white, 0.7);
    @include breakpoint(small) {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }
    .hero-content-inner {
        @include breakpoint(small) {
            width: 50%;
        }
    }
    h1 {
        margin: 0 0 5px;
        color: $colorPrimary;
    }
    p {
        font-size: 1.125rem;
    }
}
