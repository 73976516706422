/* EXPANDABLE BOX
-------------------------------------------------*/
.expandable-box {
    position: relative;
    padding: 0;
    text-align: center;
}

.expandable-box-top {
    transform: translate(0px, 0px);
    transition: transform 250ms linear;
    .expandable-box:hover & {
        transform: translate(0px, -45px);
    }
}

.expandable-box-bottom {
    @extend %clearfix;
    position: absolute;
    top: 100%;
    width: 100%;
    background: darken($colorPrimary, 5%);
    transform: translate(0px, 0px) perspective(10000px) rotateX(-90deg);
    transform-origin: top;
    transition: transform 250ms linear;
    .expandable-box:hover & {
        transform: translate(0px, -45px) perspective(10000px) rotateX(0);
    }
    a, span {
        position: relative;
        z-index: 100;
        display: inline-block;
        float: left;
        width: 100%;
        height: 45px;
        padding: 10px 0;
        color: $white;
        text-align: center;
        text-decoration: none;
        &:first-child {
            background: darken($colorPrimary, 2%);
        }
        &:last-child {
            background: lighten($colorPrimary, 4%);
        }
    }
    .icon {
        color: $white;
        font-size: 1.5rem;
    }
}
