/* NAV
-------------------------------------------------*/
.navbar-brand {
    padding: 0;
    line-height: 70px;
    > img {
        display: inline-block;
        max-width: 220px;
        max-height: 50px;
        @include breakpoint(lsmall) {
            max-width: none;
        }
    }
}

.navbar {
    margin-bottom: 0;
    border: none;
}

.navbar-default .navbar-nav>li>a,
.navbar-default .navbar-nav>li>a:focus {
    color: $colorBase;
}

.navbar-default .navbar-nav>li>a:hover {
    color: $white;
    background: lighten($colorPrimary, 2%);
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-nav>.active>a:focus {
    color: $white;
    background: darken($colorPrimary, 5%);
}

.navbar-default .navbar-toggle {
    border-color: transparent;
    background: $colorPrimary;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
    color: $white;
} 

.navbar-default .navbar-toggle .icon-bar {
    background: $white;
}