$roboto-font-path: "/fonts/roboto/" !default;
@font-face {
    font-family: "Roboto";
    src: local(Roboto Thin),
        url("#{$roboto-font-path}Roboto-Thin.woff2") format("woff2"),
        url("#{$roboto-font-path}Roboto-Thin.woff") format("woff");

    font-weight: 100;
}
@font-face {
    font-family: "Roboto";
    src: local(Roboto Light),
        url("#{$roboto-font-path}Roboto-Light.woff2") format("woff2"),
        url("#{$roboto-font-path}Roboto-Light.woff") format("woff");
    font-weight: 300;
}

@font-face {
    font-family: "Roboto";
    src: local(Roboto Regular), 
        url("#{$roboto-font-path}Roboto-Regular.woff2") format("woff2"),
        url("#{$roboto-font-path}Roboto-Regular.woff") format("woff");
    font-weight: 400;
}

@font-face {
    font-family: "Roboto";
    src: local(Roboto Medium),
        url("#{$roboto-font-path}Roboto-Medium.woff2") format("woff2"),
        url("#{$roboto-font-path}Roboto-Medium.woff") format("woff");
    font-weight: 500;
}

@font-face {
    font-family: "Roboto";
    src: local(Roboto Bold),
        url("#{$roboto-font-path}Roboto-Bold.woff2") format("woff2"),
        url("#{$roboto-font-path}Roboto-Bold.woff") format("woff");
    font-weight: 700;
}
