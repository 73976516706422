/* SVG ICONS
-------------------------------------------------*/
.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: currentColor;
}

/* =======================================================================================
Example showing how to change the color/size of the icons:
.icon-name {
    font-size: 32px; // works because "width" & "height" were set using em units
    color: red; // works for single-colored icons, because "fill" was set to "currentColor"
}
======================================================================================== */
