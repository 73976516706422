@import "utils/config";
@import "bootstrap";
@import "utils/mixins";
@import "utils/extends";

@import "base/global";
@import "base/typography";

@import "layout/layout";
@import "layout/nav";
@import "layout/footer";

@import "components/hero";
@import "components/form";
@import "components/three-shade-columns";
@import "components/section-title";
@import "components/treatments";
@import "components/about";
@import "components/testimonials";
@import "components/gallery";
@import "components/svg-icons";
@import "components/icons-style";
@import "components/expandable-box";
@import "components/tooltip";
@import "components/team";
@import "components/social";
@import "components/ie";
@import "vendor/roboto";
@import "vendor/slick";
@import "vendor/slick-theme";
